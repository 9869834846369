
import { ArrowRightBold } from '@element-plus/icons-vue'
import { computed, defineComponent, onBeforeMount, Prop, Ref, ref } from 'vue'
import axios from '@/http'
import { ElMessage } from 'element-plus'
import { IStorageSet } from '@/interfaces'
import { enumStorageSetTypes } from '@/constains'

interface IResponseStorage {
	holded: number
	quantity: number
}

interface IStorageErrorsBlocks {
	source: {
		errorId: string
		errorType: null
	}
	variationId: {
		errorId: string
		errorType: string
	}
	destination: {
		errorId: string
		errorType: null
	}
}

interface IStorageNotifyBlocks {
	source: {
		availableAfter: null
		callToAction: string
	}
	destination: {
		callToAction: string
		availableAfter: string
	}
	variationId: {
		callToAction: string
		availableAfter: string
	}
}

interface IStorageNotify {
	errors: IStorageErrorsBlocks
	notify: IStorageNotifyBlocks
	noCancel: string
}

interface IStorageBlock {
	disable: boolean
	isProp: boolean
	notify: string | null
	errorType: 'errorId' | 'errorType' | null
	value: string | number | null
	isCancel: boolean
	valid: boolean
	nameZoneAndVariationId: string | null
}

type IStorageUi = Record<keyof IStorageSet, IStorageBlock>

export default defineComponent({
	name: 'MoveMaster',

	components: {
		ArrowRightBold
	},

	props: {
		propOptions: {
			type: Object
		} as Prop<IStorageSet & { urlSet: string }>,

		urlSet: {
			type: String
		},

		dataSet: {
			type: Object,
			default: () => ({})
		}
	},

	emits: ['successMoveMaster', 'nextMoveMaster'],

	setup(props, ctx) {
		const typesChangeCell = Object.values(enumStorageSetTypes).filter(el => !Number.isInteger(el))

		const viewSuccess = ref(false)

		const options = ref<IStorageUi>({
			type: {
				value: 'q-h',
				disable: false,
				isProp: false,
				isCancel: false,
				notify: null,
				valid: false,
				errorType: null,
				nameZoneAndVariationId: null
			},
			source: {
				value: null,
				disable: true,
				isProp: false,
				isCancel: false,
				notify: null,
				valid: false,
				errorType: null,
				nameZoneAndVariationId: null
			},
			variationId: {
				value: null,
				disable: true,
				isProp: false,
				isCancel: false,
				notify: null,
				valid: false,
				errorType: null,
				nameZoneAndVariationId: null
			},
			destination: {
				value: null,
				disable: true,
				isProp: false,
				isCancel: false,
				notify: null,
				valid: false,
				errorType: null,
				nameZoneAndVariationId: null
			}
		})

		const isFetching = ref(false)

		const isSave = computed(
			() => options.value.source.valid && options.value.variationId.valid && options.value.destination.valid
		)

		const notifyStorage: IStorageNotify = {
			notify: {
				source: {
					availableAfter: null,
					callToAction: 'Отсканируйте или введите код ячейки'
				},
				variationId: {
					availableAfter: 'Станет доступно после указания исходного расположения',
					callToAction: 'Отсканируйте или введите код с бирки'
				},
				destination: {
					availableAfter: 'Станет доступно после указания кода с бирки',
					callToAction: 'Отсканируйте или введите код ячейки'
				}
			},
			errors: {
				source: {
					errorId: 'Ячейка не найдена',
					errorType: null
				},
				variationId: {
					errorId: 'Объект не найден в исходной ячейке, обратитесь к администратору!',
					errorType: 'Объект найден, но недоступен для перемещения по типу ' + options.value.type.value
				},
				destination: {
					errorId: 'Ячейка не найдена',
					errorType: null
				}
			},
			noCancel: 'Нельзя отменить'
		}

		const keysPropOptions = computed<Array<keyof IStorageSet>>(
			() => Object.keys(props.propOptions || {}) as Array<keyof IStorageSet>
		)

		function disableWithProp() {
			keysPropOptions.value.forEach(key => {
				options.value[key].isProp = true
				options.value[key].value = props.propOptions?.[key] || null

				if (key === 'type') options.value[key].disable = true
			})
		}

		function changeOptNwType() {
			if (String(options.value.type.value).includes('nw-')) {
				options.value.source.isProp = true
				options.value.source.valid = true
				options.value.source.disable = true
				options.value.source.value = null
				options.value.source.notify = notifyStorage.noCancel
			}
		}

		async function checkProp(opt: Ref<IStorageUi>) {
			const keysPropOptionsWithoutType = keysPropOptions.value.filter(key => key !== 'type') as Array<
				keyof Omit<IStorageSet, 'type'>
			>

			for (const key of keysPropOptionsWithoutType) {
				await apiCheckOptions(opt.value[key], key)
			}
		}

		function setErrorToOptionWithProp() {
			const keysWithError = keysPropOptions.value.filter(key => options.value[key].errorType)

			keysWithError.forEach(key => {
				if (options.value[key].errorType) {
					options.value[key].valid = false
					options.value[key].notify =
						notifyStorage.errors[key as keyof Omit<IStorageSet, 'type'>][options.value[key].errorType || 'errorId']
				} else {
					options.value[key].valid = true
					options.value[key].notify = notifyStorage.noCancel
				}
			})
		}

		async function onBeforeMountWrap() {
			disableWithProp()

			changeOptNwType()

			await checkProp(options)

			setErrorToOptionWithProp()

			validateOptions(options.value)
		}
		apiCheckStorage
		onBeforeMount(onBeforeMountWrap)

		function validateOptions(opt: IStorageUi) {
			const orderFields = getKeysOptions(opt) as Array<keyof Omit<IStorageSet, 'type'>>

			orderFields.forEach((key, idx) => {
				const current = opt[key]

				if (idx === 0) {
					if (current.valid) {
						current.isCancel = true
						current.notify = null
					} else {
						current.disable = false

						if (current.errorType) {
							const errorType = current.errorType as 'errorId' | 'errorType'
							current.isCancel = true

							current.notify = notifyStorage.errors[key][errorType]
						} else {
							current.isCancel = false

							current.notify = notifyStorage.notify[key].callToAction
						}
					}
				} else {
					const prev = opt[orderFields[idx - 1]]

					if (prev.valid) {
						if (current.value) {
							prev.disable = true
							prev.isCancel = false
							prev.notify = notifyStorage.noCancel
						} else {
							prev.disable = false
							prev.isCancel = true
							prev.notify = null
						}

						if (current.valid) {
							current.isCancel = true
							current.notify = null
						} else {
							current.disable = false

							if (current.errorType) {
								const errorType = current.errorType as 'errorId' | 'errorType'
								current.isCancel = true

								current.notify = notifyStorage.errors[key][errorType]
							} else {
								current.isCancel = false

								current.notify = notifyStorage.notify[key].callToAction
							}
						}
					} else {
						current.disable = true

						current.notify = notifyStorage.notify[key].availableAfter
					}
				}
			})

			addFocus()
		}

		const refsFields: { [key in keyof Omit<IStorageSet, 'type'>]: Ref } = {
			source: ref(),
			variationId: ref(),
			destination: ref()
		}

		function addFocus() {
			const keysNotDisabled = (Object.keys(options.value) as Array<keyof IStorageSet>).filter(key => {
				if (key === 'type') return false

				return !options.value[key].disable
			}) as Array<keyof Omit<IStorageSet, 'type'>>

			if (keysNotDisabled.length) {
				refsFields[keysNotDisabled[keysNotDisabled.length - 1]]?.value?.focus()
			}
		}

		function getKeysOptions(options: IStorageUi) {
			const keysWatch = Object.keys(options).filter(key => key !== 'type' && !options[key as keyof IStorageSet].isProp)

			return ['source', 'variationId', 'destination'].filter(el => keysWatch.includes(el))
		}

		function validateOnInput(val: IStorageBlock) {
			if (!val.value) clearOptionEl(val)

			validateOptions(options.value)
		}

		function changeOptionValid(optionKey: keyof Omit<IStorageSet, 'type'>, bool: boolean) {
			options.value[optionKey].valid = bool
		}

		function changeOptionErrorType(
			optionKey: keyof Omit<IStorageSet, 'type'>,
			errorKey: 'errorType' | 'errorId' | null = 'errorId'
		) {
			options.value[optionKey].errorType = errorKey
		}

		function changeOptionNameZoneAndVariationId(optionKey: keyof Omit<IStorageSet, 'type'>, name: string | null) {
			options.value[optionKey].nameZoneAndVariationId = name
		}

		function changeBlockApiCheck(
			optionKey: keyof Omit<IStorageSet, 'type'>,
			valid: boolean,
			errorKey: 'errorType' | 'errorId' | null,
			nameZone?: string | null
		) {
			changeOptionValid(optionKey, valid)
			changeOptionErrorType(optionKey, errorKey)

			if (nameZone !== undefined) changeOptionNameZoneAndVariationId(optionKey, nameZone)
		}

		async function apiCheckDestination() {
			const res = await apiGetCellEl(Number(options.value.destination.value))

			if (res?.data) {
				changeBlockApiCheck('destination', true, null, res.data.name)
			} else {
				changeBlockApiCheck('destination', false, 'errorId', null)
			}
		}

		async function apiCheckVariationId() {
			const res = await apiGetVariationEl(Number(options.value.variationId.value))

			if (res?.data) {
				const nameZone =
					res?.data.product.product?.name ||
					[res?.data.product.type.name, res?.data.product?.brand?.name, res?.data.sizeValue.name].join(' ')

				changeBlockApiCheck('variationId', true, null, nameZone)
			} else {
				changeBlockApiCheck('variationId', false, 'errorId', null)
			}
		}

		async function apiCheckStorage(val: IStorageBlock, key: keyof Omit<IStorageSet, 'type'>) {
			const data: Array<{ [key: string]: number }> = [{ cellId: Number(options.value.source.value) }]
			if (key === 'variationId') data.push({ variationId: Number(val.value) })

			const res = await apiGetStorageEl(data)

			if (res?.data) {
				if (key === 'variationId') {
					if (res?.data.variation) {
						if (checkApiDataAvailableVariations(res?.data)) {
							changeBlockApiCheck('variationId', true, null)
						} else {
							changeBlockApiCheck('variationId', false, 'errorType')
						}

						const nameZone =
							res?.data.variation.product?.name ||
							[
								res?.data.variation.product.type.name,
								res?.data.variation.product?.brand?.name,
								res?.data.variation.sizeValue.name
							].join(' ')

						changeOptionNameZoneAndVariationId('variationId', nameZone)
					} else {
						changeBlockApiCheck('variationId', false, 'errorType', null)
					}
				} else {
					changeBlockApiCheck(key, true, null, res.data.cell.name)
				}
			} else {
				if (!data[1]?.variationId) changeOptionNameZoneAndVariationId(key, null)

				changeBlockApiCheck(key, false, 'errorId', null)
			}
		}

		function checkApiDataAvailableVariations(data: IResponseStorage) {
			const type = String(options.value.type.value)

			if (type.includes('h-') && data.holded > 0) return true

			if (type.includes('q-') && data.quantity > 0) return true

			if (type.includes('qh-') && (data.quantity > 0 || data.holded > 0)) return true
		}

		async function apiCheckOptions(val: IStorageBlock, key: keyof Omit<IStorageSet, 'type'>) {
			if (key === 'destination') {
				await apiCheckDestination()
			} else if (String(options.value.type.value).includes('nw-')) {
				await apiCheckVariationId()
			} else {
				await apiCheckStorage(val, key)
			}
		}

		async function validateInputValue(val: IStorageBlock, key: keyof Omit<IStorageSet, 'type'>) {
			if (val.value) {
				await apiCheckOptions(val, key)
			} else {
				clearOptionEl(val)
			}

			validateOptions(options.value)
		}

		function evtBtnCancel(val: IStorageBlock) {
			clearOptionEl(val)

			validateOptions(options.value)
		}

		function clearOptionEl(val: IStorageBlock) {
			val.value = null
			val.valid = false
			val.errorType = null
			val.nameZoneAndVariationId = null
		}

		function changeSelect(val: string) {
			if (String(val).includes('nw-')) {
				changeOptNwType()

				clearOptions(['variationId', 'destination'])
			} else {
				options.value.source.isProp = false

				clearOptions(['source', 'variationId', 'destination'])
			}

			const arrErrorStr = notifyStorage.errors.variationId.errorType.split(' ')
			const newErrorStr = arrErrorStr.slice(0, arrErrorStr.length - 1).join(' ')
			notifyStorage.errors.variationId.errorType = newErrorStr + ' ' + String(options.value.type.value)

			validateOptions(options.value)
		}

		function clearOptions(arr: string[]) {
			arr.forEach((key, idx) => {
				const el = options.value[key as 'source' | 'variationId' | 'destination']

				if (el.isProp) return

				el.disable = idx > 0
				el.valid = false
				el.value = null
				el.notify = null
				el.errorType = null
				el.isCancel = false
				el.nameZoneAndVariationId = null
			})
		}

		async function apiGetVariationEl(id: number) {
			try {
				return await axios.get('/product/storage-find-by-id-variation/' + id)
			} catch (e) {
				return null
			}
		}

		async function apiGetStorageEl(data: Array<{ [key: string]: number }>) {
			try {
				return await axios.post('/storage/check-storage', { data })
			} catch (e) {
				return null
			}
		}

		async function apiGetCellEl(id: number) {
			try {
				return await axios.get('/storage/check-cell-by-id/' + id)
			} catch (e) {
				return null
			}
		}

		let setStorageData: IStorageSet | null = null
		let successData: unknown = null

		async function save() {
			isFetching.value = true

			const data: IStorageSet = {
				type: enumStorageSetTypes[options.value.type.value as 'q-h'],
				source: Number(options.value.source.value),
				variationId: Number(options.value.variationId.value),
				destination: Number(options.value.destination.value)
			}

			setStorageData = data

			try {
				const url = props?.urlSet ? props.urlSet : '/storage/set'
				successData = await axios.post(url, { ...data, ...props.dataSet })

				ctx.emit('successMoveMaster', { successData, setStorageData })

				viewSuccess.value = true
			} catch (e) {
				ElMessage.error('Не удалось переместить: (' + (e?.response?.data?.message || e) + ')')
			}

			isFetching.value = false
		}

		function next() {
			ctx.emit('nextMoveMaster', { successData, setStorageData })

			viewSuccess.value = false
			clearOptions(['source', 'variationId', 'destination'])
			onBeforeMountWrap()
		}

		return {
			isSave,
			options,
			viewSuccess,
			typesChangeCell,
			refsFields,
			isFetching,

			save,
			next,
			changeSelect,
			evtBtnCancel,
			validateOnInput,
			validateInputValue
		}
	}
})
